<template>
  <div>
    <ul class="flex justify-end gap-3" :class="[childClass, !showNavigationButtonsOnly && 'mt-5']">
      <!-- Left Icon to go to previous Page -->
      <li
        class="flex justify-center items-center text-text-color cursor-pointer"
        :class="[
          leftIcon && !showNavigationButtonsOnly && 'hover:bg-primary-purple-700',
          showNavigationButtonsOnly
            ? 'p-2 rounded-full border border-primary-purple-100'
            : 'h-7 w-7 border border-primary-grey-300 rounded-md ',
          previousPageExist && showNavigationButtonsOnly
            ? 'bg-primary-purple-100'
            : 'bg-primary-white',
        ]"
        @click="previousPage()"
        @mouseover="chevronLeftMouseOver()"
        @mouseleave="chevronLeftMouseLeave()"
      >
        <Icon
          :color="leftIconColor"
          :icon="rtl ? 'chevronRight' : 'chevronLeft'"
          :height="showNavigationButtonsOnly ? '20' : '16'"
          @mouseover="chevronLeftMouseOver()"
          @mouseleave="chevronLeftMouseLeave()"
        />
      </li>
      <!-- Pagination stating cells before ... -->
      <li
        v-for="cell in startCellRange"
        :key="cell"
        :class="{ 'active-cell': cell === active, hidden: showNavigationButtonsOnly }"
        class="flex bg-primary-white rounded-md justify-center items-center h-7 w-7 text-xs border border-primary-grey-300 hover:bg-primary-purple-700 text-text-color hover:text-white cursor-pointer"
        @click="selectedCell(cell)"
      >
        {{ cell }}
      </li>
      <li v-if="midCellRange.length && !showNavigationButtonsOnly">...</li>
      <!-- Pagination Mid cells  -->

      <li
        v-for="cell in midCellRange"
        :key="cell"
        :class="{ 'active-cell': cell === active, hidden: showNavigationButtonsOnly }"
        class="flex bg-primary-white rounded-md justify-center items-center h-7 w-7 text-xs border border-primary-grey-300 hover:bg-primary-purple-700 text-text-color hover:text-white cursor-pointer"
        @click="selectedCell(cell)"
      >
        {{ cell }}
      </li>
      <li v-if="endCellRange.length > 0 && !showNavigationButtonsOnly">...</li>
      <!-- Pagination end cells after ... -->
      <li
        v-for="cell in endCellRange"
        :key="cell"
        :class="{ 'active-cell': cell === active, hidden: showNavigationButtonsOnly }"
        class="flex bg-primary-white rounded-md justify-center items-center h-7 w-7 text-xs border border-primary-grey-300 hover:bg-primary-purple-700 text-text-color hover:text-white cursor-pointer"
        @click="selectedCell(cell)"
      >
        {{ cell }}
      </li>
      <li
        class="flex justify-center items-center text-text-color cursor-pointer"
        :class="[
          showNavigationButtonsOnly
            ? 'p-2 rounded-full border border-primary-purple-100'
            : 'h-7 w-7 border hover:bg-primary-purple-700 hover:text-white  border-primary-grey-300 rounded-md ',
          nextPageExist && showNavigationButtonsOnly ? 'bg-primary-purple-100' : 'bg-primary-white',
        ]"
        @click="nextPage()"
        @mouseover="chevronRightMouseOver()"
        @mouseleave="chevronRightMouseLeave()"
      >
        <Icon
          :color="rightIconColor"
          :icon="rtl ? 'chevronLeft' : 'chevronRight'"
          :height="showNavigationButtonsOnly ? '20' : '16'"
          @mouseover="chevronRightMouseOver()"
          @mouseleave="chevronRightMouseLeave()"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from '@components/icons/icon.vue'
import { startRange, endRange, midRange } from './pagination.util'

export default {
  components: {
    Icon,
  },
  props: {
    childClass: {
      type: String,
      default: 'md:mr-0 mr-5',
    },
    maxRange: {
      type: Number,
      default: 1,
    },
    pagination: {
      type: Number,
      default: 5,
    },
    recordLimit: {
      type: Number,
      default: 10,
    },
  },
  emits: ['filterRecord'],
  data() {
    return {
      rtl: false,
      startCell: 1,
      endCell: 5,
      active: 1,
      leftIcon: false,
      previousPageExist: false,
      nextPageExist: false,
      rightIcon: false,
    }
  },
  computed: {
    showNavigationButtonsOnly() {
      return this.recordLimit < 2
    },
    rightIconColor() {
      let color
      if (this.rightIcon && !this.showNavigationButtonsOnly) {
        color = 'white'
      } else if (this.nextPageExist && this.showNavigationButtonsOnly) {
        color = 'primary-purple-600'
      } else color = 'primary-gray-700'

      return color
    },
    leftIconColor() {
      let color
      if (this.leftIcon && !this.showNavigationButtonsOnly) {
        color = 'white'
      } else if (this.previousPageExist && this.showNavigationButtonsOnly) {
        color = 'primary-purple-600'
      } else color = 'primary-gray-700'
      return color
    },
    startCellRange() {
      return startRange(this.startCell, this.active, this.maxRange)
    },
    midCellRange() {
      return midRange(this.active, this.maxRange)
    },
    endCellRange() {
      return endRange(this.active, this.maxRange)
    },
  },
  created() {
    this.documentDirection()
  },
  mounted() {
    this.checkNextAndPreviousPageStatus()
  },
  methods: {
    /**
     * chevron RightMouse Over
     * @description setting the rightIcon value to true when mouse over to chevronRight
     */
    chevronRightMouseOver() {
      this.rightIcon = true
    },
    /**
     * chevron RightMouse Leave
     * @description setting the rightIcon value to false when mouse leave the chevronRight
     */
    chevronRightMouseLeave() {
      this.rightIcon = false
    },
    /**
     * Next Page
     * @description Function Description :
     * - moving to next Page
     * - emiting the filtered record to parent component
     */
    nextPage() {
      if (this.active < this.maxRange) {
        this.active++
        this.$emit('filterRecord', {
          skip: (this.active - 1) * this.recordLimit,
          limit: this.recordLimit,
        })
        this.checkNextAndPreviousPageStatus()
      }

      this.rightIcon = false
    },
    checkNextAndPreviousPageStatus() {
      this.nextPageExist = this.active < this.maxRange ? true : false
      this.previousPageExist = this.active > this.startCell ? true : false
    },
    /**
     * Previous Page
     * @description Function Description :
     * - moving to previous Page
     * - emiting the filtered record to parent component
     */
    previousPage() {
      if (this.active > this.startCell) {
        this.active--
        this.$emit('filterRecord', {
          skip: (this.active - 1) * this.recordLimit,
          limit: this.recordLimit,
        })
        this.checkNextAndPreviousPageStatus()
      }
      this.leftIcon = false
    },
    /**
     * Selected Cell
     * @param {Number} cell
     * @returns {void}
     * @description Function Description :
     * - sets the cell value to active variable
     * - emiting the filtered record to parent component
     */
    selectedCell(cell) {
      if (this.active !== cell) {
        this.active = cell
        this.$emit('filterRecord', {
          skip: (this.active - 1) * this.recordLimit,
          limit: this.recordLimit,
        })
      }
    },
    /**
     * chevron LeftMouse Over
     * @description setting the leftIcon value to true when mouse over the chevronLeft
     */
    chevronLeftMouseOver() {
      this.leftIcon = true
    },
    /**
     * chevron LeftMouse Leave
     * @description setting the leftIcon value to false when mouse leave the chevronLeft
     */
    chevronLeftMouseLeave() {
      this.leftIcon = false
    },
    /**
     * document Direction
     * @description checking that document direction is rtl or ltr
     */
    documentDirection() {
      if (document.body.dir === 'rtl') {
        this.rtl = true
      } else {
        this.rtl = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.active-cell {
  color: var(--white);
  background-color: var(--primary-purple-700);
}
</style>
