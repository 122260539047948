<template>
  <div class="flex justify-between text-base text-text-color font-roboto">
    <div>
      {{ $t(`dashboard.${label.replace('.', ' ')}`) }}
    </div>
    <label class="switch ml-2 relative inline-block w-11 h-6" :class="size">
      <input v-model="value" type="checkbox" @change="updateValue" />
      <span class="slider round absolute top-0 right-0 left-0 bottom-0 cursor-pointer"></span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'SliderButton',
  model: {
    prop: 'modelValue',
    event: 'update:modelValue',
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      value: this.modelValue,
    }
  },
  methods: {
    updateValue() {
      return this.$emit('update:modelValue', this.value)
    },
  },
}
</script>

<style lang="scss">
.switch {
  transform-origin: right;
  &.sm {
    transform: scale(0.7);
  }
}
.switch input {
  width: 0;
  height: 0;
  opacity: 0;
}
.slider {
  background-color: var(--primary-gray-200);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.slider::before {
  position: absolute;
  bottom: 3px;
  left: 3px;
  width: 20px;
  height: 20px;
  content: '';
  background-color: white;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
input:checked + .slider {
  background-color: var(--primary-purple-600);
}
input:focus + .slider {
  box-shadow: 0 0 0 4px var(--primary-purple-100);
}
input:checked + .slider::before {
  -webkit-transform: translateX(25px);
  -ms-transform: translateX(25px);
  transform: translateX(25px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 12px;
}
.slider.round::before {
  border-radius: 50%;
}
</style>
