import GENERAL_CONSTANTS from '@src/constants/general-constants'
/*
 *Use this function to create a Array of a pecific range
 * Get start and end as param use to specify the start and end of the array range
 */
function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx)
}
/*
 * Create start range for the pagination
 * If max range of pages are less than or equal to '3' and active cell is less than and eqaul to '3' start cell range is ~~[1,maxRange]
 * If max range of pages are greater than '3' but less than or equal to '5' and active cell is less than '3' start cell range is ~~[1,3]
 * If max range of pages are greater than '3' but less than or equal to '5' and active cell is greater or eqaul to '3' but less than or eqaul to 5 start cell range is ~~[1,maxRange]
 * If max range of pages is eqaul to '6' and active cell is less '3' start cell range is ~~[1,3]
 * If max range of pages is eqaul to '6' and active cell is greater and eqaul 3 but less and eqaul to '6' start cell range is ~~[1,5]
 * If max range of pages is greater than '6' and active cell less than '3' start cell range is ~~[1,3]
 * If max range of pages is greater than '6' and active cell is greater or eqaul to '3' but less than 5 start cell range is ~~[1,5]
 * Else clases start cell range is ~~[startCell,startCells]
 */
export const startRange = (startCell, activeCell, maxRange) => {
  if (maxRange <= 3 && activeCell <= 3) {
    return range(startCell, maxRange)
  } else if (maxRange > 3 && maxRange <= 5 && activeCell < 3) {
    return range(startCell, 3)
  } else if (maxRange > 3 && maxRange <= 5 && activeCell >= 3 && activeCell <= 5) {
    return range(startCell, maxRange)
  } else if (maxRange === 6 && activeCell < 3) {
    return range(startCell, 3)
  } else if (maxRange === 6 && activeCell >= 3 && activeCell <= 6) {
    return range(startCell, 5)
  } else if (maxRange > 6 && activeCell < 3) {
    return range(startCell, 3)
  } else if (maxRange > 6 && activeCell >= 3 && activeCell < 5) {
    return range(startCell, 5)
  } else {
    return range(startCell, startCell)
  }
}
/*
 * Create mid range for the pagination
 * If active cell is less than 4 and active cell is also less than or eqaul to maxRange-2 mid cell range is ~~[activeCell-1,acitveCell+1]
 * If active cell is less than 4 and active cell is also less than or eqaul to  maxRange-1 and maxRange is not eqaul to 6 mid cell range is ~~[activeCell-2,acitveCell]
 * Else clases mid cell range is ~~[]
 */
export const midRange = (activeCell, maxRange) => {
  if (activeCell > 4 && activeCell <= maxRange - 2) {
    return range(activeCell - 1, activeCell + 1)
  } else if (activeCell > 4 && activeCell <= maxRange - 1 && maxRange !== 6) {
    return range(activeCell - 2, activeCell)
  } else {
    return []
  }
}
/*
 * Create end range for the pagination
 * If maxRange is eqaul to greater than 8 and active cell is less than 3 end cell range is ~~[activeCell-1,acitveCell+1]
 * If maxRange is less than 6 end cell range is ~~[]
 * If maxRange is eqaul to actice cell and maxRange is not eqaul to 6 end cell range is ~~[maxRange-2, maxRange]
 * Else clases end cell range is ~~[maxRange,maxRange]
 */
export const endRange = (activeCell, maxRange) => {
  if (maxRange >= 8 && activeCell < 3) {
    return range(maxRange - 2, maxRange)
  } else if (maxRange < 6) {
    return []
  } else if (activeCell === maxRange && maxRange !== 6) {
    return range(maxRange - 2, maxRange)
  } else return range(maxRange, maxRange)
}

export const getPageNumberWithRange = (paginationRange, limit = GENERAL_CONSTANTS.RECORD_LIMIT) => {
  return paginationRange.skip / limit
}
export const paginationCounts = (totalCounts, filterLimit) => {
  return Math.ceil(totalCounts / filterLimit)
}

export function paginationRangeHandler(range, defaultLimit = GENERAL_CONSTANTS.RECORD_LIMIT) {
  return {
    skip: range?.skip || GENERAL_CONSTANTS.RECORD_SKIP,
    limit: range?.limit || defaultLimit,
  }
}
