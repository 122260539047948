<template>
  <UiOnClickOutside :do="closeDropdown">
    <span
      v-if="filteresList && filteresList.length"
      class="font-roboto font-normal text-text-color text-xs sm:text-sm rounded-sm text_center_style"
      @click="toggleDropdown"
    >
      <icon
        :width="elipses === 'horizontalElipses' ? '20' : '5'"
        :height="elipses === 'horizontalElipses' ? '25' : '20'"
        class="icon"
        :class="[filteresList && filteresList.length ? 'cursor-pointer' : 'pointer-events-none']"
        :icon="elipses"
      />
      <UiMenuDropDown
        v-if="filteresList && filteresList.length"
        :class="[topTooltip ? 'tooltip-top' : dropDown, minWidth]"
        :style="dropdownStyle"
        :is-open="isOpen"
        class="min-w-2 font-roboto font-normal text-text-color text-xs sm:text-sm p-1 rounded-md border border-primary-grey"
        @toggle-is-open="toggleIsOpen"
      >
        <ul>
          <div v-for="(action, index) in filteresList" :key="index">
            <li
              class="py-1.5 pl-1.5 pr-4 hover:bg-bg-color-light rounded cursor-pointer hover:text-primary-purple-600"
              @click="
                ;[
                  $emit('action', action.name, currentUser, idx, role, totalRecords),
                  action.func && action.func(),
                ]
              "
            >
              <div
                v-if="'toggle' in action"
                @click.stop="isActive = true"
                @mouseleave="isActive = false"
              >
                <SliderButton
                  v-model="action.toggle"
                  :label="action.name"
                  size="sm"
                  @triggerChange="$emit('updateToggle', action, currentUser, idx)"
                ></SliderButton>
              </div>
              <div v-else-if="action.isDynamicName" class="flex">{{ action.name }}</div>
              <div v-else class="flex">{{ $t(`options.${action.name}`) }}</div>
            </li>
          </div>
        </ul>
      </UiMenuDropDown>
    </span>
  </UiOnClickOutside>
</template>

<script>
import icon from '@components/icons/icon.vue'
import SliderButton from '@components/UiElements/SliderButton.vue'
import UiMenuDropDown from '@components/UiElements/UiMenuDropDown.vue'
import UiOnClickOutside from '@components/UiElements/UiOnClickOutside.vue'
import { mapState } from 'vuex'
import { accessLevel } from '@src/utils/generalUtil'
import scrollMixin from '@src/mixins/scroll-mixin'

export default {
  components: {
    icon,
    SliderButton,
    UiMenuDropDown,
    UiOnClickOutside,
  },
  mixins: [scrollMixin],

  props: {
    dropDown: {
      type: String,
      default: 'tableDropDowntop',
    },
    minWidth: {
      type: String,
      default: '',
    },
    totalRecords: { type: Number, default: 0 },
    role: {
      type: String,
      default: '',
    },
    topTooltip: {
      type: Boolean,
      default: false,
    },

    actionList: {
      type: Array,
      default: () => [],
    },
    currentUser: {
      type: [Object, String],
      default: () => {},
    },
    idx: {
      type: [Number, String],
      default: 0,
    },
    status: {
      type: String,
      default: '',
    },
    top: {
      type: Number,
      default: function (props) {
        return (props.idx + 1) * 72 - 13
      },
    },
    right: {
      type: Number,
      default: 95,
    },
    rtlTop: {
      type: Number,
      default: -24,
    },
    rtlRight: {
      type: Number,
      default: -245,
    },
    elipses: {
      type: String,
      default: 'horizontalElipses',
    },
  },
  emits: ['action', 'updateToggle'],
  data() {
    return {
      isActive: false,
      isOpen: false,
      isDirRight: false,
    }
  },
  computed: {
    ...mapState('layout', [
      'activeRole',
      'currentSectionScope',
      'currentCampusScope',
      'currentClassScope',
    ]),
    filteresList() {
      let list = []
      this.actionList.forEach((item) => {
        if (
          accessLevel(
            item,
            this.currentCampusScope,
            this.currentSectionScope,
            this.currentClassScope,
          ) &&
          this.childRoleAccess(item.roles)
        )
          list.push(item)
      })
      return list
    },
    dropdownStyle() {
      return this.isDirRight
        ? { top: `${this.rtlTop}px`, right: `${this.rtlRight}px` }
        : { top: `${this.top}px`, right: `${this.right}px` }
    },
  },
  /**
   * watch Hook
   */
  watch: {
    isScrolling: {
      handler() {
        this.isOpen = false
      },
    },
  },
  /**
   * Created Hook
   * @description Description :
   * - To detect the RTL direction of body,
   * - So we can implement component RTL too.
   */
  created() {
    if (document.getElementsByTagName('body')[0].dir === 'rtl') this.isDirRight = true
  },
  methods: {
    childRoleAccess(roles = []) {
      let returnVal = true
      if (roles && Array.isArray(roles) && roles.length && !roles.includes(this.activeRole)) {
        returnVal = false
      }
      return returnVal
    },
    /**
     * Close dropdown
     * @description closing the drop down menus
     */
    closeDropdown() {
      if (!this.isActive && this.isOpen) {
        this.toggleIsOpen()
      }
    },
    /**
     * Toggle dropdown
     * @description toggling dropdown
     */
    toggleDropdown() {
      this.toggleIsOpen()
    },
    /**
     * toggle IsOpen
     * @param {Boolean | *} value
     * @returns {void}
     * @description toggling the value of IsOpen
     */
    toggleIsOpen(value) {
      this.isOpen = typeof value === 'boolean' ? value : !this.isOpen
    },
  },
}
</script>

<style lang="scss" scoped>
.tooltip-top {
  &::before {
    position: absolute;
    top: -7px;
    right: 17px;
    width: 12px;
    height: 12px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--primary-grey);
    border-left: 1px solid var(--primary-grey);
    transform: rotate(45deg);
  }
}
.tableDropDowntop {
  &::before {
    position: absolute;
    top: 19px;
    right: -7px;
    width: 12px;
    height: 12px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--primary-grey);
    border-left: 1px solid var(--primary-grey);
    transform: rotate(135deg);
  }
}
.tableDropDownbottom {
  &::before {
    position: absolute;
    right: -7px;
    bottom: 19px;
    width: 12px;
    height: 12px;
    content: '';
    background: var(--bg-white);
    border-top: 1px solid var(--primary-grey);
    border-left: 1px solid var(--primary-grey);
    transform: rotate(135deg);
  }
}
</style>

<style lang="scss">
.min-width-2 {
  min-width: 150px;
}
.text_center_style {
  text-align: -webkit-center;
}
</style>
