<template>
  <!-- <ValidationObserver v-slot="{ invalid, handleSubmit }" tag="div"> -->
  <UiModalContainer
    footer-classes="flex justify-end gap-3"
    :modal-show="showTypeModal"
    :modal-width="50"
    @handleClick="handleClick"
  >
    <template v-slot:header>
      <span v-if="!updateTypeButton">Add {{ header }} Type</span>
      <span v-else>Edit {{ header }} Type</span>
    </template>
    <template v-slot>
      <div>
        <div class="flex flex-col">
          <InputBox
            v-model.trim="salaryTypeObject.title"
            type="text"
            name="Title"
            title="Title"
            label="Title"
            placeholder="Write"
            :class="$style.widthItems"
            class="flex-1 w-full"
            rules="required"
          />
          <InputBox
            v-model="salaryTypeObject.description"
            type="text"
            name="Description"
            title="Description"
            label="Description"
            placeholder="Description"
            :class="$style.widthItems"
            class="flex-1 w-full"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <button
        v-i18n="salary"
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button-outline"
        @click="handleClick('close')"
      >
        Cancel
      </button>
      <button
        class="focus:outline-none focus:shadow-md text-base text-white font-roboto h-8 flex justify-center items-center primary-button"
        @click.prevent="handleSubmit(addSalaryType(invalid))"
      >
        <span v-if="isModalLoading">
          <Loader />
        </span>
        <span v-else v-i18n="salary">
          <span v-if="!updateTypeButton">Save</span>
          <span v-else>Update</span>
        </span>
      </button>
    </template>
  </UiModalContainer>
  <!-- </ValidationObserver> -->
</template>

<script>
import InputBox from '@components/UiElements/UiInputBox.vue'
import UiModalContainer from '@components/UiElements/UiModalContainer.vue'
import Loader from '@components/BaseComponent/Loader.vue'
// import { ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import { mapActions, mapState } from 'vuex'
import { objectDeepCopy } from '@utils/generalUtil'

export default {
  name: 'SalaryTypes',
  components: {
    UiModalContainer,
    InputBox,
    // ValidationObserver,
    Loader,
  },
  props: {
    header: {
      type: String,
      required: true,
      validator(value) {
        return ['Allowance', 'Bonus', 'Deduction'].includes(value)
      },
    },
    editableObject: { type: Object, default: () => null },
  },
  emits: ['update'],
  data() {
    return {
      salary: 'salary',
      salaryTypeObject: { title: '', description: '' },
      updateTypeButton: false,
      edit: null,
    }
  },

  computed: {
    ...mapState({
      showTypeModal: (state) => state.salary.showModal,
      isModalLoading: (state) => state.salary.isModalLoading,
    }),
  },
  created() {
    if (this.editableObject) {
      this.salaryTypeObject = objectDeepCopy(this.editableObject)
      this.updateTypeButton = true
      return
    }
  },

  methods: {
    /**
     * Reset Form
     * @description Resetting fee type name and description to empty value
     */
    resetForm() {
      this.salaryTypeObject.title = ''
      this.salaryTypeObject.description = ''
    },

    /**
     * Add Salary Types
     * @param {boolean} invalid
     * @returns {void}
     * @description Method description
     * -  Edit existing fee type
     * -  Show toast regarding to action we perform eg:Create or Edit
     * -  Setting right bar data
     */
    async addSalaryType(invalid) {
      if (invalid || this.isModalLoading) {
        return
      }
      switch (this.header) {
        case 'Allowance':
          this.addSalaryAllowanceType()
          break
        case 'Bonus':
          // add/update salary bonus type
          this.addSalaryBonusType()
          break
        case 'Deduction':
          // add/update salary deduction type
          this.addSalaryDeductionType()
          break
      }
    },

    async addSalaryAllowanceType() {
      let res, err
      if (this.updateTypeButton) {
        ;[res, err] = await this.updateAllowanceType(this.salaryTypeObject)
      } else {
        ;[res, err] = await this.createAllowanceType(this.salaryTypeObject)
      }
      if (!err) {
        this.handleClick('close')
        this.$emit('update')
      }
    },

    async addSalaryBonusType() {
      let res, err
      if (this.updateTypeButton) {
        ;[res, err] = await this.updateBonusType(this.salaryTypeObject)
      } else {
        ;[res, err] = await this.createBonusType(this.salaryTypeObject)
      }
      if (!err) {
        this.handleClick('close')
        this.$emit('update')
      }
    },

    async addSalaryDeductionType() {
      let res, err
      if (this.updateTypeButton) {
        ;[res, err] = await this.updateDeductionType(this.salaryTypeObject)
      } else {
        ;[res, err] = await this.createDeductionType(this.salaryTypeObject)
      }
      if (!err) {
        this.handleClick('close')
        this.$emit('update')
      }
    },
    /**
     * Handle click
     * @param {string} eventName
     * @returns {void}
     * @description Calling setShowModal and resetForm method on the basis of event name
     */
    handleClick(eventName) {
      switch (eventName) {
        case 'showModalOpen':
          this.showModal()
          break
        case 'close':
          this.hideModal()
          this.resetForm()
          this.updateTypeButton = false

          break
      }
    },

    ...mapActions('salary', [
      'showModal',
      'hideModal',
      'createAllowanceType',
      'updateAllowanceType',
      'createBonusType',
      'updateBonusType',
      'createDeductionType',
      'updateDeductionType',
    ]),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
