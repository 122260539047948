import debounce from 'lodash/debounce'

export default {
  data() {
    return {
      leftScroll: false,
      rightScroll: false,
      swipeLeft: true,
      swipeRight: true,
      element: null,
      scrollStep: 50,
      tableId: 'table-container',
      isScrolling: false,
      scrollTimeout: null,
    }
  },
  mounted() {
    this.widthDetector()
  },
  updated() {
    this.widthDetector()
  },
  computed: {
    leftBlurArea() {
      this.swipeLeft = true
      let response = this.leftScroll ? 'blur-left-side' : ''
      this.swipe_left_hide()
      return response
    },
    rightBlurArea() {
      this.swipeRight = true
      let response = this.rightScroll ? 'blur-right-side' : ''
      this.swipe_right_hide()
      return response
    },
  },
  methods: {
    widthDetector() {
      this.element = document.getElementById(this.tableId)
      if (this.element) {
        new ResizeObserver(() => {
          this.scrollDetect()
        }).observe(this.element)
        this.element.addEventListener('scroll', this.scrollHandler)
      }
    },
    swipe_left_hide: debounce(function () {
      this.swipeLeft = false
    }, 3000),
    swipe_right_hide: debounce(function () {
      this.swipeRight = false
    }, 3000),

    scrollDetect() {
      this.leftScroll = this.element?.scrollLeft > 2
      let scrollRight =
        this.element?.scrollWidth - this.element?.clientWidth - this.element?.scrollLeft
      this.rightScroll = scrollRight > 2
    },
    scrollLeft() {
      this.element.scrollLeft -= this.scrollStep
    },
    scrollRight() {
      this.element.scrollLeft += this.scrollStep
    },
    scrollHandler() {
      this.scrollDetect()
      this.isScrolling = true
      clearTimeout(this.scrollTimeout)
      this.scrollTimeout = setTimeout(() => {
        this.isScrolling = false
      }, 500)
    },
  },
  beforeDestroy() {
    if (this.element) {
      this.element.removeEventListener('scroll', this.scrollHandler)
    }
    clearTimeout(this.scrollTimeout)
  },
}
